import { template as template_40f09b5a9ff441df85134d4b6f28733c } from "@ember/template-compiler";
const FKLabel = template_40f09b5a9ff441df85134d4b6f28733c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
