import { template as template_6e82e7cc08c446989a3738c7b4ce054b } from "@ember/template-compiler";
const FKText = template_6e82e7cc08c446989a3738c7b4ce054b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
