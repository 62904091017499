import { template as template_ea35f571b5224a5d8a590c379bd03457 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ea35f571b5224a5d8a590c379bd03457(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
